import React from 'react';
import Scrollspy from 'react-scrollspy';
class Nav extends React.Component {
	render() {
		return (
			<nav className="Nav-wrap">
				<Scrollspy items={ ['ben', 'about', 'work', 'skills'] } currentClassName="active">
					<li><a href="#ben">Welcome</a></li>
					<li><a href="#about">About</a></li>
					<li><a href="#work">Work</a></li>
					<li><a href="#skills">Skiils</a></li>
				</Scrollspy>
			</nav>
		)
	}
}

export default Nav;