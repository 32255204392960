import React from "react";

class Section1 extends React.Component {
	render() {
		return(
			<article id="ben" className="Section-1 Section width-limit">
				<div className="Section-1-left">
					 <picture className="Landing__picture"> 
						<source srcSet={'beard-max.jpg'} media="(min-width: 1600px)"/>
						<source srcSet={'beard-desk.jpg'} media="(min-width: 1024px)"/>
						<source srcSet={'beard-tablet.jpg'} media="(min-width: 768px)"/>
						<source srcSet={'beard-600.jpg'} media="(max-width: 768px)"/>
						<img src="/beard-max.jpg" alt="Ben Beard Web Developer"/>
					</picture>  

				</div>
				<div className="Section-1-right">
					<h1 className="font-title color-light">Benjamin <span>Beard</span></h1>
					<span></span>
					<h3 className="font-title color-light">Web Developer</h3>
					<p className="Now-working color-light">Available for freelance projects</p>
					<div className="Contact-home">
						<p className="font-title color-light">901.649.4798</p>
						<p className="font-title color-light Footer-middle"><a className="Link-hover" href="mailto:ben@benabeard.com">Ben@benabeard.com</a></p>
						<p className="font-title color-light"><a className="Link-hover"  target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/benarenbeard/">LinkedIn</a></p>
					</div>
				</div>
		</article>
		)
	}
}

export default Section1;