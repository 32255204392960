import * as React from "react";
import Nav from "../components/nav";
import Section1 from "../components/section1";
import Section2 from "../components/section2";
import Section3 from "../components/section3";
import Section4 from "../components/section4";
import Foot from "../components/foot";

import { Link } from 'gatsby';
// markup
const IndexPage = () => {
  return (

		<>
		<Section1/>
		<Section2/>
		<Section3/>
		<Section4/>
		<Foot/>
		<Nav/>
		</>
  )
}

export default IndexPage
