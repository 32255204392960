import React from 'react';

class Foot extends React.Component {
	render() {
		return(
			<footer id="contact" className="Section-5 color-light">
				<p className="font-title ">901.649.4798</p>
				<p className="font-title color-light Footer-middle"><a className="Link-hover"  href="mailto:ben@benabeard.com">Ben@benabeard.com</a></p>
				<p className="font-title color-light"><a className="Link-hover"  target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/benarenbeard/">LinkedIn</a></p>
			</footer>
		)
	}
}

export default Foot;