import React from "react";

class Section2 extends React.Component {
	render (){ 
		return (
			<article id="about" className="Section-text Section padding-large">
				<div className="Section-2-top ">
				<h2 className="font-title">functional <span>and</span> beautiful</h2>
				<div>
					<p>I develop websites that are both functional and beautiful.
						With eight years experience in both an agency and freelance environment, I have worked with clients operating non-profits, small busniesses, and large corporations. I enjoy the challenges of bringing innovative designs to life, while emphasising both useability and expandability. </p>
					</div>
				</div>
			</article>
		)
	}
}

export default Section2;