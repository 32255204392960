import React from "react";
import { Link } from 'gatsby';
class Section3 extends React.Component {
	render () { 
		return (

			<article id="work" className="Section-work Section width-limit">
			<h3 className="font-title text-center color-light">Select Work</h3>
			<div className="Portfolio">
				<div className="Portfolio-pice spca">
					<Link to="/laspca" state={{modal: true}}>
						<p className="font-title">Louisiana <span>SPCA</span></p>
						<picture className="Portfolio__picture">
							<source srcset={"/work/spca/landing.jpg"} media="(min-width: 48em)" />
							<source srcset={"/work/spca/landing-m.jpg"} media="(max-width: 48em)" />
							<img src={"/work/spca/landing.jpg"} alt="Louisiana SPCA | Ben Beard Developer" />
						</picture>
					</Link>
				</div>
				<div className="Portfolio-pice unum">
					<Link to="/unim" state={{modal: true}}>
						<p className="font-title">E Pluribus <span>Unum Fund</span></p>
						<picture className="Portfolio__picture">
							<source srcset={"/work/unum/landing.jpg"} media="(min-width: 48em)" />
							<source srcset={"/work/unum/landing-m.jpg"} media="(max-width: 48em)" />
							<img src={"/work/unum/landing.jpg"} alt="Unum SPCA | Ben Beard Developer" />
						</picture>
					</Link>
				</div>
				<div className="Portfolio-pice lang">
					<Link to="/lang" state={{modal: true}}>
						<p className="font-title">Langenstein's <span>Grocery</span></p>
						<picture className="Portfolio__picture">
							<source srcset={"/work/lang/landing.jpg"} media="(min-width: 48em)" />
							<source srcset={"/work/lang/landing-m.jpg"} media="(max-width: 48em)" />
							<img src={"/work/lang/landing.jpg"} alt="Langensteins Grocery | Ben Beard Developer" />
						</picture>
					</Link>
				</div>
				<div className="Portfolio-pice beignet">
					<Link to="/beignet" state={{modal: true}}>
						<p className="font-title">Beignet <span>Fest</span></p>
						<picture className="Portfolio__picture">
							<source srcset={"/work/beignet/landing.jpg"} media="(min-width: 48em)" />
							<source srcset={"/work/beignet/landing-m.jpg"} media="(max-width: 48em)" />
							<img src={"/work/beignet/landing.jpg"} alt="Gallo Mechanical | Ben Beard Developer" />
						</picture>
					</Link>
				</div>
				<div className="Portfolio-pice walton">
					<Link to="/walton" state={{modal: true}}>
						<p className="font-title">Walton <span>Family Foundation</span></p>
						<picture className="Portfolio__picture">
							<source srcset={"/work/walton/landing.jpg"} media="(min-width: 48em)" />
							<source srcset={"/work/walton/landing-m.jpg"} media="(max-width: 48em)" />
							<img src={"/work/walton/landing.jpg"} alt="Walton Family Foundation | Ben Beard Developer" />
						</picture>
					</Link>	
				</div>
				<div className="Portfolio-pice srsa">
				<Link to="/srsa" state={{modal: true}}>
					<p className="font-title">SRSA <span>Real Estate</span></p>
					<picture className="Portfolio__picture">
							<source srcset={"/work/srsa/landing.jpg"} media="(min-width: 48em)" />
							<source srcset={"/work/srsa/landing-m.jpg"} media="(max-width: 48em)" />
						<img src={'work/srsa/landing.jpg'} alt="SRSA | Ben Beard Developer" />
					</picture>
					</Link>
				</div>
			</div>
		</article>
		)
	}
}

export default Section3;