import React from "react";

class Section4 extends React.Component {
	render() {
		return(
			<>
			<article id="clients" className="Section Section-halfs background-dark">
				<section className="Clients-half">
					<div className="Half-text Section-text padding-medium color-light">
						<h3 className="font-title">Addtional Clients</h3>
						<ul>
							<li><p>Zeus' Rescues</p></li>
							<li><p>Randazzo’s Camellia City Bakery<span className="comp-color">*</span></p></li>
							<li><p>Gallo Mechanical<span className="comp-color">*</span></p></li>
							<li><p>New Orleans Running of the Bulls</p></li>
							<li><p>Teach New Orleans<span className="comp-color">*</span></p></li>
							<li><p>New Orleans Tricentennial Commission<span className="comp-color">*</span></p></li>
						</ul>
						<br/>
						<span><span className="comp-color">*</span>Done by me, at an agency.</span>
					</div>
				<img className="img-fit" src={'clients.jpg'} alt="" />
				</section>
			</article>

			<article id="skills" className=" Section-halfs">
				<section className="Skills-half">
					<img className="img-fit" src={'skills.jpg'} alt="" />
					<div className="Half-text Section-text padding-medium">
						<h3 className="font-title">Skills</h3>
						<ul>
							<li><p>HTML</p></li>
							<li><p>SCSS/CSS</p></li>
							<li><p>JAVASCRIPT ES6</p></li>
							<li><p>JQUERY</p></li>
							<li><p>REACT JS</p></li>
							<li><p>GATSBY JS</p></li>
							<li><p>PHP</p></li>
							<li><p>SQL</p></li>
							<li><p>WORDPRESS</p></li>
							<li><p>WOOCOMMERCE</p></li>
							<li><p>ADOBE SUITE</p></li>
						</ul>
					</div>
				</section>
			</article>
			</>
		)
	}
}

export default Section4;